// @ts-nocheck
import React from "react";
import { FaMoneyCheckAlt, FaWhatsapp } from "react-icons/fa"; // Importe o ícone do WhatsApp
import { Button } from "./ui/MovingBorders"; // Importe o botão animado

const ComparisonSection = () => {
  return (
    <section className="flex flex-col items-center justify-center p-10 space-y-8">
      {/* Card Com Ativo Pay */}
      <div className="flex flex-col bg-gradient-to-br from-purple-900 to-purple-700 text-white p-6 rounded-lg shadow-lg max-w-lg">
        <div className="flex items-center space-x-2 mb-4">
          <FaMoneyCheckAlt className="text-3xl text-purple-300" />
          <h3 className="text-lg font-semibold">Você fatura mais de 100 mil por mês?</h3>
        </div>
        <p className="text-lg font-medium mb-4">
          Temos taxas exclusivas para seu negócio. Clique abaixo e entre em contato conosco para garantir as melhores condições do mercado.
        </p>
        
        <a
          href="https://wa.me/5511912512403?text=Quero%20fazer%20meu%20cadastro%20na%20PagShield"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            duration={10000}
            borderRadius="1.75rem"
            containerClassName="mt-6"
            className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center justify-center hover:bg-green-600 transition-colors"
          >
            {/* Adicione o ícone do WhatsApp e o texto */}
            <FaWhatsapp className="mr-2 text-xl" />
            Solicitar taxas exclusivas
          </Button>
        </a>
      </div>
    </section>
  );
};

export default ComparisonSection;
