import React from 'react';

function Bloco07() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '30vh',
    }}>
      <div style={{
        backgroundColor: '#161b22',
        borderRadius: '12px',
        padding: '20px',
        maxWidth: '400px',
        color: '#c9d1d9',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.8)',
        textAlign: 'left',
        position: 'relative',
        border: '2px solid transparent',
        borderImage: 'linear-gradient(90deg, #a64dff, #d966ff, #a64dff) 1',
        animation: 'borderMove 4s infinite linear'
      }}>
        <h4 style={{ color: '#58a6ff', margin: 0, padding: 0 }}>PagShield é facilidade com conversões altíssimas.</h4>
        <h2 style={{ color: '#f0f6fc', fontWeight: 'bold', margin: 0, padding: 0 }}>Chega de PIX não pago!</h2>

        <div style={{
          background: '#2a2f37',
          borderRadius: '12px',
          overflow: 'hidden',
          height: '24px',
          marginTop: '10px',
          marginBottom: '10px'
        }}>
          <div style={{
            width: '98.7%',
            height: '100%',
            background: 'linear-gradient(90deg, #a64dff, #d966ff, #a64dff)',
            backgroundSize: '200% 100%',
            animation: 'progressMove 2s infinite linear'
          }} />
        </div>

        <p style={{
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#8b949e',
          margin: 0,
          padding: 0
        }}>
          98,70% de conversão, testado e comprovado
        </p>
      </div>

      <style>
        {`
          @keyframes progressMove {
            0% { background-position: 0% 0; }
            100% { background-position: 100% 0; }
          }

          @keyframes borderMove {
            0% { border-image-source: linear-gradient(90deg, #a64dff, #d966ff, #a64dff); }
            100% { border-image-source: linear-gradient(90deg, #d966ff, #a64dff, #d966ff); }
          }
        `}
      </style>
    </div>
  );
}

export default Bloco07;
