// components/Faq.tsx
"use client";

import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface FAQItem {
  question: string;
  answer: string;
}

const faqItems: FAQItem[] = [
  { question: "o que é um gateway de pagamento?", answer: "Um gateway de pagamento é um serviço que processa transações de cartão de crédito e PIX para empresas online e físicas." },
  { question: "Na PagShield tenho um gerente de conta?", answer: "Sim, Na PagShield voce tera um gerente de conta dedicado para auxiliar em todas as suas necessidades." },
  { question: "Qual a taxa de aprovação?", answer: "Nossa taxa de aprovação média é de 98,7%, uma das mais altas do mercado." },
  { question: "Minha conta será bloqueada se eu vender infoproduto?", answer: "Não, a PagShield aceita a venda de infoprodutos, desde que estejam em conformidade com nossas políticas." },
  { question: "A PagShield funciona para meu e-commerce?", answer: "Basta se cadastrar, verificar sua conta e começar a utilizar nossos serviços." },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="w-full py-16 px-6">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold text-purple-400 text-center mb-8 tracking-wide">
        PERGUNTAS FRENQUENTES        
        </h1>
        <div className="space-y-4">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg"
              style={{ borderColor: '#7D4BB1' }} // Cor da borda roxa
            >
              <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h2 className="text-lg text-white font-medium tracking-wide">
                  {item.question}
                </h2>
                {activeIndex === index ? (
                  <FiChevronUp className="text-[#7D4BB1] transition-transform duration-300" />
                ) : (
                  <FiChevronDown className="text-[#7D4BB1] transition-transform duration-300" />
                )}
              </div>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeIndex === index ? "max-h-96" : "max-h-0"
                }`}
              >
                <p className="p-4 text-gray-300 text-base leading-relaxed">
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
