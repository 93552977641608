// @ts-nocheck
import React, { useState, useEffect } from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { MdOutlineNotificationsActive, MdTrendingUp } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { Button } from "./ui/MovingBorders"; // Importe o botão animado

const AppFeatureSection = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    // Função para atualizar o estado com base no tamanho da janela
    const updateScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); // 1024px é o breakpoint 'lg' no Tailwind CSS
    };

    updateScreenSize(); // Define o estado inicial

    window.addEventListener("resize", updateScreenSize); // Atualiza o estado ao redimensionar

    return () => window.removeEventListener("resize", updateScreenSize); // Limpeza
  }, []);

  return (
    <section className="flex flex-col items-center justify-center p-6 space-y-8">
      {/* Layout Responsivo */}
      <div className="w-full max-w-screen-lg">
        {isLargeScreen ? (
          // Versão para telas grandes
          <div className="relative">
            <div className="mockup-phone rounded-lg shadow-lg overflow-hidden">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/player-music-72d4d.appspot.com/o/img%2Fiphone%20mockup%20(1).jpg?alt=media&token=0fcaaa50-62fa-4820-bd5a-0f9d02383066"
                alt="App Mockup"
                className="w-full h-full object-cover"
              />
              {/* Sobreposição semitransparente */}
              <div className="absolute inset-0 bg-black opacity-50"></div>
              {/* Texto Sobreposto */}
              <div className="absolute inset-0 flex items-center justify-start">
                <div className="relative flex flex-col items-start space-y-4 p-6 lg:p-12">
                  <div className="flex items-center space-x-2 text-white">
                    <FaApple className="text-3xl" />
                    <FaGooglePlay className="text-3xl" />
                  </div>
                  <h2 className="text-3xl font-bold text-white">
                    Acompanhe tudo na palma da sua mão
                  </h2>

                  <div className="space-y-3">
                    <div className="flex items-start space-x-2">
                      <RiMoneyDollarCircleLine className="text-2xl text-white" />
                      <h3 className="text-lg font-semibold text-white">
                        Acompanhe o seu saldo em tempo real
                      </h3>
                    </div>
                    <div className="flex items-start space-x-2">
                      <MdOutlineNotificationsActive className="text-2xl text-white" />
                      <h3 className="text-lg font-semibold text-white">
                        Receba notificações a cada venda realizada
                      </h3>
                    </div>
                    <div className="flex items-start space-x-2">
                      <MdTrendingUp className="text-2xl text-white" />
                      <h3 className="text-lg font-semibold text-white">
                        Tenha o controle total da sua operação
                      </h3>
                    </div>
                  </div>

                  {/* Botões */}
                  <div className="flex space-x-4">
                    <Button
                      duration={10000}
                      borderRadius="1.75rem"
                      className="px-4 py-2 bg-[#2b1b4e] text-white rounded-lg flex items-center space-x-2"
                    >
                      <FaApple />
                      <span>App Store</span>
                    </Button>
                    <Button
                      duration={10000}
                      borderRadius="1.75rem"
                      className="px-4 py-2 bg-[#2b1b4e] text-white rounded-lg flex items-center space-x-2"
                    >
                      <FaGooglePlay />
                      <span>Play Store</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Versão para telas menores
          <div className="flex flex-col items-center space-y-6">
            <div className="mockup-phone rounded-lg shadow-lg overflow-hidden">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/player-music-72d4d.appspot.com/o/img%2Fiphone%20mockup%20(1).jpg?alt=media&token=0fcaaa50-62fa-4820-bd5a-0f9d02383066"
                alt="App Mockup"
                className="w-full h-full object-cover"
              />
            </div>
            {/* Texto e Botões abaixo da imagem */}
            <div className="flex flex-col items-center space-y-4 px-4">
              <div className="flex items-center space-x-2 text-purple-700">
                <FaApple className="text-3xl" />
                <FaGooglePlay className="text-3xl" />
              </div>
              <h2 className="text-2xl font-bold text-purple-700 text-center">
                Acompanhe tudo na palma da sua mão
              </h2>

              <div className="space-y-3">
                <div className="flex items-start space-x-2">
                  <RiMoneyDollarCircleLine className="text-2xl text-purple-700" />
                  <h3 className="text-lg font-semibold text-purple-700">
                    Acompanhe o seu saldo em tempo real
                  </h3>
                </div>
                <div className="flex items-start space-x-2">
                  <MdOutlineNotificationsActive className="text-2xl text-purple-700" />
                  <h3 className="text-lg font-semibold text-purple-700">
                    Receba notificações a cada venda realizada
                  </h3>
                </div>
                <div className="flex items-start space-x-2">
                  <MdTrendingUp className="text-2xl text-purple-700" />
                  <h3 className="text-lg font-semibold text-purple-700">
                    Tenha o controle total da sua operação
                  </h3>
                </div>
              </div>

              {/* Botões */}
              <div className="flex space-x-4">
                <Button
                  duration={10000}
                  borderRadius="1.75rem"
                  className="px-4 py-2 bg-[#2b1b4e] text-white rounded-lg flex items-center space-x-2"
                >
                  <FaApple />
                  <span>App Store</span>
                </Button>
                <Button
                  duration={10000}
                  borderRadius="1.75rem"
                  className="px-4 py-2 bg-[#2b1b4e] text-white rounded-lg flex items-center space-x-2"
                >
                  <FaGooglePlay />
                  <span>Play Store</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AppFeatureSection;
