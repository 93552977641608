"use client";

import { navItems } from "@/data";
import Hero from "@/components/Hero";
import Grid from "@/components/Grid";
import Footer from "@/components/Footer";
import Clients from "@/components/Clients";
import Approach from "@/components/Approach";
import Experience from "@/components/Experience";  
import { FloatingNav } from "@/components/ui/FloatingNavbar";
import TaxasTarifas from "@/components/Taxas";
import FAQ from "@/components/Faq";
import AppFeatureSection from "@/components/Appm"
import Bloco07 from "@/components/Bloco07";
import FacebookCoverPost from "@/components/Face"
const Home = () => {
  return (
<main className="relative bg-black-100 flex justify-center items-center flex-col mx-auto sm:px-10 px-5 overflow-x-hidden">
  <div className="max-w-7xl w-full m-0 p-0">
    <FloatingNav navItems={navItems} />
    <Hero />
    <Experience />
    <Clients />
    <AppFeatureSection/>
    <Bloco07/>
    <Approach />
    <TaxasTarifas />
    <FAQ />
    <Footer />
  </div>
</main>

  );
};

export default Home;
