// @ts-nocheck
import React from "react";
import { motion } from "framer-motion";
import { CanvasRevealEffect } from "./ui/CanvasRevealEffect";
import { FiZap, FiCalendar, FiUser, FiStar, FiTarget, FiHeart } from "react-icons/fi";

const Approach = () => {
  return (
    <section className="w-full py-2">
      <div className="my-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 items-center justify-center w-full">
        <Card
          icon={<FiZap className="text-blue-400 w-8 h-8 mb-4" />}
          title="Mais Vendas Aprovadas"
          des="Aumente a taxa de aprovação dos seus pagamentos. Com nossa tecnologia de ponta, garantimos maior segurança e eficiência nas suas transações."
        >
          <CanvasRevealEffect animationSpeed={5.1} containerClassName="bg-emerald-900 rounded-3xl overflow-hidden" />
        </Card>
        <Card
          icon={<FiCalendar className="text-blue-400 w-8 h-8 mb-4" />}
          title="Saque aos Finais de Semana"
          des="Disponível 24 horas por dia, 7 dias por semana. Faça saques de forma prática e rápida, mesmo aos sábados e domingos."
        >
          <CanvasRevealEffect animationSpeed={3} containerClassName="bg-pink-900 rounded-3xl overflow-hidden" colors={[[255, 166, 158], [221, 255, 247]]} dotSize={2} />
        </Card>
        <Card
          icon={<FiUser className="text-blue-400 w-8 h-8 mb-4" />}
          title="Gerente de Contas"
          des="Atendimento personalizado para o seu negócio. Tenha à disposição um gerente dedicado para atender todas as suas necessidades e dúvidas."
        >
          <CanvasRevealEffect animationSpeed={3} containerClassName="bg-sky-600 rounded-3xl overflow-hidden" colors={[[125, 211, 252]]} />
        </Card>
        <Card
          icon={<FiStar className="text-blue-400 w-8 h-8 mb-4" />}
          title="Diferenciais"
          des="Inovação e qualidade em nossos serviços. Oferecemos diferenciais que proporcionam uma experiência superior para nossos clientes."
        >
          <CanvasRevealEffect animationSpeed={3} containerClassName="bg-indigo-600 rounded-3xl overflow-hidden" colors={[[135, 211, 252]]} />
        </Card>
        <Card
          icon={<FiTarget className="text-blue-400 w-8 h-8 mb-4" />}
          title="Missão"
          des="Compromisso com o sucesso dos nossos clientes. Nossa missão é apoiar o crescimento do seu negócio com soluções personalizadas e eficazes."
        >
          <CanvasRevealEffect animationSpeed={3} containerClassName="bg-purple-600 rounded-3xl overflow-hidden" colors={[[145, 211, 252]]} />
        </Card>
        <Card
          icon={<FiHeart className="text-blue-400 w-8 h-8 mb-4" />}
          title="Valores"
          des="Ética, transparência e foco no cliente. Guiamos nossas ações pelos princípios da ética, sempre priorizando o que é melhor para nossos parceiros."
        >
          <CanvasRevealEffect animationSpeed={3} containerClassName="bg-teal-600 rounded-3xl overflow-hidden" colors={[[155, 211, 252]]} />
        </Card>
      </div>
    </section>
  );
};

export default Approach;

const Card = ({ icon, title, children, des }) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="border border-gray-700 rounded-lg p-6 w-full max-w-sm mx-auto text-white  hover:bg-opacity-95 transition relative"
    >
      {hovered && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }} // Reduzir a opacidade para manter a legibilidade
          className="h-full w-full absolute inset-0 z-0"
        >
          {children}
        </motion.div>
      )}
      <div className="relative z-10 flex flex-col items-center text-center space-y-4">
        {icon}
        <h2 className="text-lg font-semibold text-white drop-shadow-lg">{title}</h2>
        <p className="text-sm text-gray-300 drop-shadow-lg">{des}</p>
      </div>
    </div>
  );
};
