// @ts-nocheck
import React from "react";
import { workExperience } from "@/data";
// components/Faq.tsx
import { Button } from "./ui/MovingBorders";

const Experience = () => (
  <section className="py-10 w-full">
    <div className="container mx-auto mt-12 grid lg:grid-cols-4 grid-cols-1 gap-9">
      {workExperience.map(({ id, title, desc }) => {
        const lines = desc.split('\n').filter(line => line.trim() !== ""); // Filtra linhas vazias
        const hasMultipleLines = lines.length > 1;

        return (
          <Button
            key={id}
            duration={Math.floor(Math.random() * 10000) + 10000}
            borderRadius="1.75rem"
            style={{
              background: "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
            }}
            className="flex-1 text-black dark:text-white border border-neutral-200 dark:border-slate-800"
          >
            <div className="flex flex-col lg:items-start p-5 md:p-7 lg:p-10 gap-3">
              <h2 className="text-start text-xl md:text-2xl font-bold">
                {title}
              </h2>
              {hasMultipleLines ? (
                <ul className="text-start text-gray-200 mt-2 font-medium space-y-2 list-disc pl-5">
                  {lines.map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
                </ul>
              ) : (
                <p className="text-start text-gray-200 mt-2 font-medium">{desc}</p>
              )}
            </div>
          </Button>
        );
      })}
    </div>
  </section>
);

export default Experience;
