// @ts-nocheck
"use client";
import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "@/lib/utils";
import "@fontsource/space-grotesk/700.css"; // Fonte Space Grotesk com peso negrito

export const TextGenerateEffect = ({ className }) => {
  const [scope, animate] = useAnimate();

  const text = `mais que uma\nplataforma de\npagamentos\nsomos PARCEIROS\nem sua jornada!`;
  const wordsArray = text.split(/\s+/); // Divide o texto por palavras, respeitando as quebras de linha

  useEffect(() => {
    animate(
      "span",
      {
        opacity: 1,
        filter: "blur(0px)", // Gradualmente remove o blur
      },
      {
        duration: 1,
        delay: stagger(0.3), // Controle de atraso entre as animações das palavras
      }
    );
  }, [scope, animate]);

  const RenderWords = () => (
    <motion.div ref={scope} className="whitespace-pre-line"> {/* Mantém as quebras de linha */}
      {wordsArray.map((word, idx) => (
        <motion.span
          key={word + idx}
          initial={{ opacity: 0, filter: "blur(3px)" }} // Começa com opacidade zero e leve blur
          animate={{ opacity: 1, filter: "blur(0px)" }}
          className={`${
            word === "PARCEIROS" ? "text-purple-500 font-bold" : "text-white font-bold"
          } ${idx !== wordsArray.length - 1 ? "mr-1" : ""}`} // Usa font-bold para garantir consistência de estilo
        >
          {word}{" "}
        </motion.span>
      ))}
    </motion.div>
  );

  return (
    <div className={cn("font-spacerange", className)}> {/* Define a fonte spacerange */}
      <div className="my-4">
        <div className="leading-snug tracking-wide">
          <RenderWords />
        </div>
      </div>
    </div>
  );
};
